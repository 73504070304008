html, body, #app, #app>div, #root {
  height: 100%
  
}

.body-container {
  margin-right: 20px;
  margin-left: 20px;
  height:100%
}

.bg {
  background-image: url("Media/header-image.jpg");
  height: 70vmin;
  width:  100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerText {
  font-family: 'Playfair Display';
  font-size: 3vmax;
  color: white;
  text-align: center;
  position: relative;
  top: 40%;
  left: 10%;
}

.body {
  padding-left: 20vw;
  padding-right: 20vw;
  background-color: #eae7dc;
  color: #123c69
}

h1 {
  color: #ac3b61
}